/* eslint-disable @typescript-eslint/no-explicit-any */
import { FCWithChildren } from '../types/FCWithChildren';

export type SlotType<T> = FCWithChildren<{ name: T }>;

const Slot: SlotType<any> = () => null;

const withSlot = <TProps, TSlots>(component: FCWithChildren<TProps & { _slots: TSlots }>): FCWithChildren<TProps> & { Slot: SlotType<TSlots> } => {
  const c = component as FCWithChildren<TProps> & { Slot: SlotType<TSlots> };
  c.Slot = Slot;

  return c;
};

type ExtractReturnTypes<T> = T extends IterableIterator<infer R> ? R : never;
export type SlotDefinitions<T extends any[]> = ExtractReturnTypes<ReturnType<T['values']>>;

export default withSlot;
